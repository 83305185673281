@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
}
