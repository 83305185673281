@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .hover-card-effect {
    transform: perspective(var(--hover-perspective, 1rem))
      rotateY(var(--rotate-y)) rotateX(var(--rotate-x));
  }
}

@layer utilities {
  .loader-clip-path {
    clip-path: polygon(
      100% 50%,
      100% 100%,
      0 100%,
      0 0,
      100% 0,
      100% 50%,
      97.5% 50%,
      97.264% 45.271%,
      96.558% 40.588%,
      95.39% 35.999%,
      93.77% 31.549%,
      91.716% 27.283%,
      89.246% 23.242%,
      86.387% 19.468%,
      83.166% 15.996%,
      79.616% 12.863%,
      75.771% 10.099%,
      71.67% 7.731%,
      67.354% 5.783%,
      62.865% 4.275%,
      58.248% 3.222%,
      53.55% 2.633%,
      48.816% 2.515%,
      44.094% 2.869%,
      39.43% 3.691%,
      34.872% 4.973%,
      30.464% 6.703%,
      26.25% 8.864%,
      22.272% 11.433%,
      18.57% 14.385%,
      15.18% 17.692%,
      12.136% 21.319%,
      9.469% 25.232%,
      7.204% 29.391%,
      5.365% 33.754%,
      3.969% 38.279%,
      3.031% 42.92%,
      2.559% 47.632%,
      2.559% 52.368%,
      3.031% 57.08%,
      3.969% 61.721%,
      5.365% 66.246%,
      7.204% 70.609%,
      9.469% 74.768%,
      12.136% 78.681%,
      15.18% 82.308%,
      18.57% 85.615%,
      22.272% 88.567%,
      26.25% 91.136%,
      30.464% 93.297%,
      34.872% 95.027%,
      39.43% 96.309%,
      44.094% 97.131%,
      48.816% 97.485%,
      53.55% 97.367%,
      58.248% 96.778%,
      62.865% 95.725%,
      67.354% 94.217%,
      71.67% 92.269%,
      75.771% 89.901%,
      79.616% 87.137%,
      83.166% 84.004%,
      86.387% 80.532%,
      89.246% 76.758%,
      91.716% 72.717%,
      93.77% 68.451%,
      95.39% 64.001%,
      96.558% 59.412%,
      97.264% 54.729%,
      97.5% 50%
    );
  }
}

@import '@bits/tailwind-config/styles.css';

.simplebar-track.simplebar-vertical,
.simplebar-track.simplebar-horizontal {
  z-index: theme(zIndex.50);
}

.simplebar-track .simplebar-scrollbar::before {
  background-color: theme('colors.ink');
}
